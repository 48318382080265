import React ,{useEffect}from 'react'
import { useNavigate } from 'react-router-dom';
function ExamplePDFViewer() {
  const navigate = useNavigate();
  const pdfurl=localStorage.getItem('pdfurl');
  const userCheck=localStorage.getItem('userCheck');
  useEffect(() => {
      if (userCheck!='yes'){
          navigate("/")
      }
    });
  if(userCheck=='yes'){
      return( 
      <div className="video">
        <iframe width="100%" height="650" src={pdfurl} />
      </div>
  )
      
  }
  else{
      return(
        <div></div>
      )
  }
}

export default ExamplePDFViewer;