import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import React from 'react';
// import Video from './video';
import ExamplePDFViewer from'./pdf'
import Form from './form';
import List from './list';
import '../css/nav.css'
function Index(){
    return(
        <div>
            <ul className="topnav">
                <li><a class="active" href="/list">Home</a></li>
            </ul>
            
            <Router>
                <Routes>
                    <Route exact path='/' element={< Form />}></Route>
                    <Route exact path='/list' element={< List />}></Route>
                    <Route exact path='/pdf' element={< ExamplePDFViewer />}></Route>
                    {/* <Route exact path='/video' element={< Video />}></Route> */}
                </Routes>
            </Router>
           
        </div>
    )
}
export default Index;