import React ,{useEffect,useState} from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../css/list.css';
import { BASE_URL } from "./apiUrl";
import ReactPlayer from 'react-player'
function List(){
    const [data,setData]=useState([{"pdf":[],"video":[]}]);
    const navigate = useNavigate();
    const text =window.location.href
    const myArray = text.split("?");
    if (myArray.length>1){
        const urlParams = new URLSearchParams("?"+myArray[1]);
        for (const entry of urlParams.entries()) {
            console.log(entry);
            if (entry[0]==="name"){
                
                localStorage.setItem('name', entry[1]);
            }
            else if(entry[0]==="dob") {

                localStorage.setItem('birthday', entry[1]);
            }
        }}
    
    const birthday=localStorage.getItem('birthday');
    const name=localStorage.getItem('name');
    axios.get(BASE_URL+`/data?name=${name}&dob=${birthday}`, {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
    })
        .then((response) =>  localStorage.setItem('userCheck',"yes")).catch(error=>navigate('/'));
    const userCheck=localStorage.getItem('userCheck');
    console.log("userCheck",typeof(userCheck))
    function getData(){
        const birthday=localStorage.getItem('birthday');
        const name=localStorage.getItem('name');
        axios.get(BASE_URL+`/data?name=${name}&dob=${birthday}`, {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        })
        .then((response) => setData(response.data)).catch(error=>navigate('/'));
        }
    
    function read(pdfurl){
        localStorage.setItem('pdfurl', pdfurl);
        navigate('/pdf')
    }
    function watch(probs){
        console.log("probs",probs)
        localStorage.setItem('videoUrl', probs);
        navigate('/video')
    }
    useEffect(() => {
        if (userCheck!="yes"){
            navigate("/")
        }
        getData();
    },[]);
    if(userCheck=='yes'){
    return (
        <div>
            {data.video?.map((video,index) => (
                    <React.Fragment key={index} >
                        <div class="card">
                            <ReactPlayer controls={true} url={video.url} />
                                <div class="container">
                                    <h4><b>{video.file}</b></h4>  
                                </div>
                        </div>
                    </React.Fragment>
                ))}
           
                {data.pdf?.map((pdf,index) => (
                    
                    <React.Fragment key={index} >
                         <div className="table">
                        <div>
                            {pdf.file}
                        </div>
                        <div>
                            <div class="btn-group">
                            <button  onClick={()=>read(pdf.url)} >Download File</button>
                            {/* <a href={pdf.url} target="_blank" rel="noopener noreferrer" download>
                                <button>
                                    <i className="fas fa-download"/>
                                    Download File
                                </button>
                            </a> */}
                        </div>
            </div>
            </div>
                    </React.Fragment>
                ))}
            
        </div>
    )}
    else{
        return(
            <div></div>
        )
    }
}
export default List;