import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React,{useState} from "react";
import '../css/form.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from "./apiUrl";
function Form(){
    console.log("BASE_URL",BASE_URL)
    const [name,setName]=useState(null);
    const [birthday,setBirthday]=useState(new Date());
    const [userCheck,setUserCheck]=useState(null);
    const navigate = useNavigate();
    function handleClick(event) {
        event.preventDefault();
        localStorage.setItem('birthday', null);
        localStorage.setItem('name', name);
        localStorage.setItem('birthday', birthday.toLocaleDateString());
        console.log(birthday.toLocaleDateString().length,birthday.toLocaleDateString())
        localStorage.setItem('userCheck', userCheck);
        const data={name:name,dob:birthday.toLocaleDateString()}
        axios.post(BASE_URL+"/login", JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        })
        .then((response) => setUserCheck(response.data)).catch(error=>alert('Invalid username or birthday'));
        }
       
        if (userCheck===true){
            localStorage.setItem('userCheck', 'yes');
            navigate('/list')
        }
    return(
        <div className="rootDiv">
            <div className="container">
                <form>
                    <div className="row">
                            <div className="col-25">
                                <label htmlFor="fname">Name</label>
                            </div>
                            <div className="col-75">
                                <input type="text" name="name" placeholder="name.." required
                                onChange={(event) => setName(event.target.value)}
                                />
                            </div>
                    </div>
                    <div className="row">
                            <div className="col-25">
                                <label htmlFor="birthday">Birthday</label>
                            </div>
                            <div className="col-75">
                                <DatePicker selected={birthday} onChange={(date) => setBirthday(date) } dateFormat="dd/MM/yyyy" />
                            </div>
                    </div>
                    <div className="row">
                        <input type="submit" value="Submit"  onClick={handleClick}/>
                    </div>
                </form>

            </div>
        </div>
    )
}
export default Form;